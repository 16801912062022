import { BlobItem } from "@azure/storage-blob";
import { decryptData } from "../utils/crypto";
import { BloabUploadResponse } from "../models/BlobUploadResponse";
import { Dispatch, SetStateAction } from "react";

declare global {
  interface Window {
    _env_: any;
  }
}

const backendUri = window._env_.BACKEND_URI;
const encryptionSalt = window._env_.ENCRYPTION_SALT;

export async function loadBlobs(containerName: string): Promise<BlobItem[]> {
  let blobs: BlobItem[] = [];

  const encyptedAcessToken = localStorage.getItem("accessToken");

  if (encyptedAcessToken) {
    const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

    if (accessToken !== null) {
      const token = JSON.parse(accessToken).token;
      try {
        const response = await fetch(
          backendUri + "/containers/" + containerName + "/blobs",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        const payload = await response.json();

        if (payload.error) {
          throw new Error(payload.error);
        }

        blobs = payload;
      } catch (error: any) {
        alert("Error loading blobs: " + error.message);
      }
    } else {
      alert("Error retrieving access token");
    }
  }

  return blobs;
}

export async function uploadBlob(
  selectedFiles: File[],
  containerName: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setCount: Dispatch<SetStateAction<number>>,
  setBlobs: Dispatch<SetStateAction<BlobItem[] | { error: string; } | undefined>>
): Promise<BloabUploadResponse[]> {
  const numberBatchItems = 15;
  const timeoutMs = 3000; // Time delay between batches
  const results: BloabUploadResponse[] = [];
  const encyptedAcessToken = localStorage.getItem("accessToken");

  if (encyptedAcessToken) {
    const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

    if (accessToken !== null) {
      const token = JSON.parse(accessToken).token;
      const slices = Math.ceil(selectedFiles.length / numberBatchItems);

      // Create a function to process each batch of files
      const processBatch = async (batchFiles: File[], batchIndex: number) => {
        return new Promise<void>((resolve) => {
          setTimeout(async () => {
            // Process each file in the batch
            for (const file of batchFiles) {
              try {
                const formData = new FormData();
                formData.append("blob", file, file.name);

                const response = await fetch(
                  backendUri + "/containers/" + containerName + "/blobs/",
                  {
                    method: "POST",
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                    body: formData,
                  }
                );

                if (response.status !== 200) {
                  const payload = await response.json();
                  throw new Error(payload.error);
                } else {
                  results.push({ file, isUploaded: true });
                }
              } catch (error: any) {
                results.push({ file, isUploaded: false });
                alert("Error loading blob: " + error.message);
              }
            }

            resolve(); // Resolve after all files in the batch are processed
          }, batchIndex * timeoutMs); // Delay the next batch by `timeoutMs` milliseconds
        });
      };

      // Create promises for each batch
      const batchPromises = [];
      for (let i = 0; i < slices; ++i) {
        const batchFiles = selectedFiles.slice(i * numberBatchItems, (i + 1) * numberBatchItems);
        batchPromises.push(processBatch(batchFiles, i));
      }

      // Wait for all batches to complete
      try {
        await Promise.all(batchPromises); // Wait for all batches to finish
        setLoading(false); // End loading after all batches are done

        const newBlobs: BlobItem[] = await loadBlobs(containerName);
        setBlobs(newBlobs);
        setCount(newBlobs.length);
      } catch (error) {
        console.error("Error during blob upload: ", error);
        setLoading(false);
      }

    } else {
      alert("Error retrieving access token");
      setLoading(false);
    }
  } else {
    console.error("Error retrieving access token");
    setLoading(false);
  }

  return results;
}

export async function downloadBlobFromBuffer(
  containerName: string,
  blobName: string
): Promise<Blob> {
  let blob: Blob = new Blob();

  const encyptedAcessToken = localStorage.getItem("accessToken");

  if (encyptedAcessToken) {
    const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

    if (accessToken !== null) {
      const token = JSON.parse(accessToken).token;
      try {
        const response = await fetch(
          backendUri + "/containers/" + containerName + "/blobs/" + blobName,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (response.status !== 200)
          throw new Error(
            "Received error from server with code " + response.status
          );

        blob = await response.blob();
      } catch (error: any) {
        alert("Error downloading blob: " + error.message);
      }
    } else {
      alert("Error retrieving access token");
    }
  }

  return blob;
}

// export async function downloadBlob(
//   containerName: string,
//   blobName: string
// ): Promise<string> {
//   let blobUrl: string = "";

//   const encyptedAcessToken = localStorage.getItem("accessToken");

//   if (encyptedAcessToken) {
//     const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

//     if (accessToken !== null) {
//       const token = JSON.parse(accessToken).token;
//       try {
//         const response = await fetch(
//           backendUri + "/containers/" + containerName + "/blobs/" + blobName,
//           {
//             method: "GET",
//             headers: {
//               Authorization: "Bearer " + token,
//             },
//           }
//         );

//         const payload = await response.json();

//         if (payload.error) {
//           throw new Error(payload.error);
//         }

//         if (response.status !== 200)
//           throw new Error(
//             "Received error from server with code " + response.status
//           );

//         blobUrl = payload;
//       } catch (error: any) {
//         alert("Error downloading blob: " + error.message);
//       }
//     } else {
//       alert("Error retrieving access token");
//     }
//   }

//   return blobUrl;
// }

export async function deleteBlob(
  containerName: string,
  blobName: string
): Promise<boolean> {
  let isDeleted: boolean = false;

  const encyptedAcessToken = localStorage.getItem("accessToken");

  if (encyptedAcessToken) {
    const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

    if (accessToken !== null) {
      const token = JSON.parse(accessToken).token;
      try {
        const response = await fetch(
          backendUri + "/containers/" + containerName + "/blobs/" + blobName,
          {
            method: "DELETE",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (response.status !== 200) {
          const payload = await response.json();

          if (payload.error) {
            throw new Error(payload.error);
          }
        }

        isDeleted = true;
      } catch (error: any) {
        alert("Error deleting blob: " + error.message);
      }
    } else {
      alert("Error retrieving access token");
    }
  }

  return isDeleted;
}
